import moment from "moment";
export function getUpdatedDate(date) {
  const today = new Date();
  const yesterday = new Date(today);
  const dayBeforeYesterday = new Date(today);
  const dateObject = new Date(date);
  yesterday.setDate(yesterday.getDate() - 1);
  dayBeforeYesterday.setDate(yesterday.getDate() - 2);
  if (dateObject > today) {
    return `${moment(yesterday).format("ll")}`;
  } else {
    return `${moment(dayBeforeYesterday).format("ll")}`;
  }
}

export function isMobile() {
  const mobileThreshold = 800;
  if (typeof window !== "undefined") {
    if (window.innerWidth < mobileThreshold) {
      return true;
    }
  }
  return false;
}
